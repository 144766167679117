import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import api from '../index';

// Get one
// Get all
export const getLoad = async (id) => {
  try {
    const response = await api.get(`/loads/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Get all
export const getLoads = async (page, take, filter, sort) => {
  let url = "/loads";

  if (page && take) {
    url = `${url}?pagination[page]=${page}&pagination[take]=${take}`
  }

  if (filter) {
    Object.keys(filter).forEach(item => {
      url = `${url}&filter[${item}]=${filter[item]}`
    });
  }

  if (sort) {
    url = `${url}&sort[${sort['id']}]=${sort['desc'] ? 'DESC' : 'ASC'}`
  }

  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getLoadsByCitiesSuggestions = async (query, page, take) => {
  let url = `/loads/cities/${query}`;

  if (page && take) {
    url = `${url}?pagination[page]=${page}&pagination[take]=${take}`
  }

  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Create
const prepareDataToSendForCreateOrUpdate = (data) => {
  const loadWeight = Number(data.weight);
  const loadLength = Number(data.lenght);
  const loadEquipmentType = Number(data.equipmentType);

  if (data.pickupTime) {
    data.pickupTime = moment(data.pickupTime).format("HH:mm:ss");
  } else {
    data.pickupTime = null;
  }

  if (data.dropOffTime) {
    data.dropOffTime = moment(data.dropOffTime).format("HH:mm:ss");
  } else {
    data.dropOffTime = null;
  }

  return {
    load_origin: data.cityOriginId,
    load_destination: data.cityDestinationId,
    load_pickup: moment(data.pickupDate).format("YYYY-MM-DD"),
    load_pickup_hours: data.pickupTime,
    load_pickup_drop_off_hours: data.dropOffTime,
    equipment_details: data.equipmentSize,
    load_equipment_type: loadEquipmentType,
    load_length: loadLength,
    load_weight: loadWeight,
    additional_information: data.comments,
    load_rate: data.rate,
    load_mode: data.loadMode,
  };
};

export const createdNewLoad = async (data) => {
  try {
    data = prepareDataToSendForCreateOrUpdate(data);

    await api.post("/loads", data);
    toast.success('Load created successfully');

    return true;
  } catch (error) {
    let message = 'Error creating load';

    if (error?.response?.data?.message) {
      message = error.response.data.message;
    }

    toast.error(message);
    throw error;
  }
}

// Multiple eliminations
export const multipleEliminationsLoads = async (ids) => {
  try {
    await api.post("loads/multiple-eliminations", {
      ids
    });
    toast.success('Loads successfully removed');
  } catch (error) {
    let message = 'Error when deleting loads';

    if (error?.response?.data?.message) {
      message = error.response.data.message;
    }

    toast.error(message);
    throw error;
  }
}

// Update
export const editLoad = async (data) => {
  try {
    const id = data.id;
    data = prepareDataToSendForCreateOrUpdate(data);

    await api.put(`/loads/${id}`, data);
    toast.success('Load updated successfully');

    return true;
  } catch (error) {
    let message = 'Error editing load';

    if (error?.response?.data?.message) {
      message = error.response.data.message;
    }

    toast.error(message);
    throw error;
  }
}

// Select for carrier
export const selectLoad = async (id) => {
  try {
    await api.put(`/loads/${id}/select`);
    toast.success('Successfully selected load');

    return true;
  } catch (error) {
    let message = 'Loading error when selected';

    if (error?.response?.data?.message) {
      message = error.response.data.message;
    }

    toast.error(message);
    throw error;
  }
}