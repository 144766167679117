import React, { useRef, useState, useEffect } from "react";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { Button, Form, InputGroup } from "@themesberg/react-bootstrap";

import "./Modal.scss";
import { getAllEquipmentType } from "../../../services/api/equipmentType";
import stateCodes from "../../../data/statesCodes";

const ModalForFilters = ({ closeModal, searchNewData }) => {
  const ref = useRef();

  const [equipmentType, setEquipmentType] = useState([]);

  const [selectOrigin, setSelectOrigin] = useState("");
  const [selectDestination, setSelectDestination] = useState("");
  const [selectEquipmentType, setSelectEquipmentType] = useState("");

  const [pickupDate, setPickupDate] = useState(null);
  const [calendarOpen, setCalendarOpen] = useState(false);

  const handleClearFilter = () => {
    setSelectOrigin("");
    setSelectDestination("");
    setSelectEquipmentType("");
    setPickupDate(null);
    setCalendarOpen(false);
  };

  const handleDateChange = (date) => {
    setPickupDate(date);
    setCalendarOpen(false);
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newFilter = {
      origin_state_code: selectOrigin,
      destination_state_code: selectDestination,
      equipment_name: selectEquipmentType,
      load_pickup:
        pickupDate && moment(pickupDate).isValid()
          ? moment(pickupDate).format("YYYY-MM-DD")
          : "",
    };

    searchNewData(1, 10, newFilter, null);
    handleCloseModal();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllEquipmentType();
        setEquipmentType(response);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div ref={ref} className="modalContainerFilterOverlay">
      {/* Modal Container */}
      <div className="modalFilterView">
        {/* Modal header */}
        <div className="modalFilterViewHeader">
          <h2> Browse Carrier Options </h2>

          {/* Close button for small screen */}
          <button
            type="button"
            className="d-block d-sm-block d-md-block d-lg-none d-xl-none btn-close"
            onClick={handleCloseModal}
          />
        </div>

        {/* Modal body */}
        <div className="modalFilterViewBody">
          <Form onSubmit={handleSubmit}>
            {/* Trailer type Dropdown */}
            <div className="form-group">
              <Form.Label>Trailer type</Form.Label>
              <Form.Select
                className="form-select mb-3"
                id="country"
                aria-label="Select a type"
                value={selectEquipmentType}
                onChange={(e) => setSelectEquipmentType(e.target.value)}
              >
                <option value="" disabled>
                  Select a type
                </option>
                {equipmentType.map((type) => (
                  <option key={type.equipment_id} value={type.equipment_name}>
                    {type.equipment_name}
                  </option>
                ))}
              </Form.Select>
            </div>

            {/* Origin Dropdown */}
            <div className="form-group">
              <Form.Label>Origin</Form.Label>
              <Form.Select
                className="form-select mb-3"
                value={selectOrigin}
                aria-label="select a state"
                onChange={(e) => setSelectOrigin(e.target.value)}
              >
                <option value="" disabled>
                  Select a state
                </option>
                {stateCodes.map((stateCode) => (
                  <option key={stateCode.id} value={stateCode.state_code}>
                    {stateCode.state_code}
                  </option>
                ))}
              </Form.Select>
            </div>

            {/* Destination Dropdown */}
            <div className="form-group">
              <Form.Label>Destination</Form.Label>
              <Form.Select
                className="mb-3"
                value={selectDestination}
                onChange={(e) => setSelectDestination(e.target.value)}
                aria-label="select a state"
              >
                <option value="" disabled>
                  Select a state
                </option>
                {stateCodes.map((stateCode) => (
                  <option key={stateCode.id} value={stateCode.state_code}>
                    {stateCode.state_code}
                  </option>
                ))}
              </Form.Select>
            </div>

            {/* Pick up day selector */}
            <div className="form-group mt-3" id="pickupDay">
              <Form.Label>Pick up day</Form.Label>
              <Datetime
                value={pickupDate}
                timeFormat={false}
                onChange={handleDateChange}
                open={calendarOpen}
                onFocus={() => setCalendarOpen(true)}
                closeOnSelect={true}
                renderInput={(props, openCalendar) => (
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      value={
                        pickupDate
                          ? moment(pickupDate).format("MM/DD/YYYY")
                          : ""
                      }
                      placeholder="mm/dd/yyyy"
                      onFocus={openCalendar}
                      onChange={(e) => setPickupDate(e.target.value)}
                      onClick={() => setCalendarOpen(true)}
                    />
                  </InputGroup>
                )}
              />
            </div>

            {/* Actions button */}
            <div className="d-flex flex-column gap-2 mt-3">
              <Button className="btn btn-primary" type="submit">
                Search loads
              </Button>
              <button
                className="btn btn-warning"
                type="button"
                onClick={handleClearFilter}
              >
                Clear filter
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ModalForFilters;
