import React, { useEffect, useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck
} from "@fortawesome/free-solid-svg-icons";
import {
  Button
} from "@themesberg/react-bootstrap";
import moment from "moment-timezone";

import Table from "../../components/Tables/Table";
import ConfirmModal from "../../components/Modal/ConfirmModal";
import { getCarriers, putAcceptCarrier } from "../../services/api/carriers";

export default () => {
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [loading, setLoading] = useState(true);
  const [acceptCarrierId, setAcceptCarrierId] = useState(null);
  const [showModalAccept, setShowModalAccept] = useState(false);
  const [loadingAcceptCarrier, setLoadingAcceptCarrier] = useState(false);

  const columns = [
    {
      header: "#",
      footer: (props) => props.column.id,
      accessorKey: "id",
      cell: (info) => info.getValue(),
      sort: true
    },
    {
      header: "First Name",
      footer: (props) => props.column.id,
      accessorKey: "user_first_name",
      cell: (info) => info.getValue(),
    },
    {
      header: "Last Name",
      footer: (props) => props.column.id,
      accessorKey: "user_last_name",
      cell: (info) => info.getValue(),
    },
    {
      header: "MC or DOT Number",
      footer: (props) => props.column.id,
      accessorKey: "user_detail.carrier_type",
      cell: (info) => info.getValue(),
    },
    {
      header: "MC or DOT Number",
      footer: (props) => props.column.id,
      accessorKey: "user_detail.carrier_type_value",
      cell: (info) => info.getValue(),
    },
    {
      header: "Company",
      footer: (props) => props.column.id,
      accessorKey: "user_detail.company_name",
      cell: (info) => info.getValue(),
    },
    {
      header: "Email",
      footer: (props) => props.column.id,
      accessorKey: "user_email",
      cell: (info) => info.getValue(),
    },
    {
      header: "Date of application",
      footer: (props) => props.column.id,
      accessorKey: "createdAt",
      cell: (info) => moment(info.getValue()).format("MM-DD-YYYY"),
    },
    {
      header: "Accept",
      footer: (props) => props.column.id,
      accessorKey: "is_valid",
      cell: (info) => {
        const statusVariant =
          info.row.original.is_valid
            ? "success"
            : "danger";
        const textValue =
          info.row.original.is_valid
            ? "YES"
            : "NO";

        return (
          <span className={`text-${statusVariant}`}>{textValue}</span>
        );
      },
    },
    {
      header: "Action",
      cell: (info) => {
        if (info.row.original.is_valid) return (<></>);

        return (
          <Button size="sm" variant="primary" onClick={() => acceptCarrier(info.row.original.id)}>
            <FontAwesomeIcon icon={faCheck} className="me-2" /> Accept
          </Button>
        )
      },
    },
  ];
  const [initialSorting, setInitialSorting] = useState([
    {
      desc: true,
      id: "id"
    }
  ]);

  const closeModal = () => {
    setAcceptCarrierId(null);
    setShowModalAccept(false);
  }

  const acceptCarrier = (id) => {
    setAcceptCarrierId(id);
    setShowModalAccept(true);
  }

  const handleAccept = () => {
    setLoadingAcceptCarrier(true);
    putAcceptCarrier(acceptCarrierId)
      .then(() => {
        const newList = list.map(item => {
          if (item.id === acceptCarrierId) {
            item.is_valid = true;
          }

          return item;
        });
        setList(newList);
        closeModal();
      })
      .finally(() => setLoadingAcceptCarrier(false));
  }

  const getPaginationList = useCallback((numberPage, numberTake, filter, sort) => {
    setLoading(true);
    getCarriers(numberPage, numberTake, filter, sort)
      .then((response) => {
        setList(response.data);
        setPagination({
          pageCount: response.pagination.pageCount,
          pageSize: response.pagination.take,
          pageIndex: response.pagination.page - 1,
          itemCount: response.pagination.itemCount,
        });
      })
      .finally(() => setLoading(false));
  }, []);

  const handleSortingChange = (value) => {
    setInitialSorting(value);
    getPaginationList(
      1,
      10,
      null,
      value[0]
    );
  }

  useEffect(() => {
    getPaginationList(1, 10);
  }, [getPaginationList]);

  return (
    <>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <Table
          columns={columns}
          data={list}
          pagination={pagination}
          handlePagination={getPaginationList}
          handleSortingChange={handleSortingChange}
          initialSorting={initialSorting}
        />
      )}
      <ConfirmModal
        show={showModalAccept}
        textBody={'Are you sure you want to accept the carrier\'s registration?'}
        handleClose={() => closeModal()}
        handleYes={() => handleAccept()}
        isLoading={loadingAcceptCarrier}
      />
    </>
  );
};
