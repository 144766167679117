export default [
  {
    id: 1,
    state_code: "AL"
  },
  {
    id: 2,
    state_code: "AK"
  },
  {
    id: 3,
    state_code: "AZ"
  },
  {
    id: 4,
    state_code: "AR"
  },
  {
    id: 5,
    state_code: "CA"
  },
  {
    id: 6,
    state_code: "CO"
  },
  {
    id: 7,
    state_code: "CT"
  },
  {
    id: 8,
    state_code: "DE"
  },
  {
    id: 9,
    state_code: "FL"
  },
  {
    id: 10,
    state_code: "GA"
  },
  {
    id: 11,
    state_code: "HI"
  },
  {
    id: 12,
    state_code: "ID"
  },
  {
    id: 13,
    state_code: "IL"
  },
  {
    id: 14,
    state_code: "IN"
  },
  {
    id: 15,
    state_code: "IA"
  },
  {
    id: 16,
    state_code: "KS"
  },
  {
    id: 17,
    state_code: "KY"
  },
  {
    id: 18,
    state_code: "LA"
  },
  {
    id: 19,
    state_code: "ME"
  },
  {
    id: 20,
    state_code: "MD"
  },
  {
    id: 21,
    state_code: "MA"
  },
  {
    id: 22,
    state_code: "MI"
  },
  {
    id: 23,
    state_code: "MN"
  },
  {
    id: 24,
    state_code: "MS"
  },
  {
    id: 25,
    state_code: "MO"
  },
  {
    id: 26,
    state_code: "MT"
  },
  {
    id: 27,
    state_code: "NE"
  },
  {
    id: 28,
    state_code: "NV"
  },
  {
    id: 29,
    state_code: "NH"
  },
  {
    id: 30,
    state_code: "NJ"
  },
  {
    id: 31,
    state_code: "NM"
  },
  {
    id: 32,
    state_code: "NY"
  },
  {
    id: 33,
    state_code: "NC"
  },
  {
    id: 34,
    state_code: "ND"
  },
  {
    id: 35,
    state_code: "OH"
  },
  {
    id: 36,
    state_code: "OK"
  },
  {
    id: 37,
    state_code: "OR"
  },
  {
    id: 38,
    state_code: "PA"
  },
  {
    id: 39,
    state_code: "RI"
  },
  {
    id: 40,
    state_code: "SC"
  },
  {
    id: 41,
    state_code: "SD"
  },
  {
    id: 42,
    state_code: "TN"
  },
  {
    id: 43,
    state_code: "TX"
  },
  {
    id: 44,
    state_code: "UT"
  },
  {
    id: 45,
    state_code: "VT"
  },
  {
    id: 46,
    state_code: "VA"
  },
  {
    id: 47,
    state_code: "WA"
  },
  {
    id: 48,
    state_code: "WV"
  },
  {
    id: 49,
    state_code: "WI"
  },
  {
    id: 50,
    state_code: "WY"
  },
]