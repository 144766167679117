import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Col,
  Row,
  Container,
  Image,
  Dropdown,
  Card,
  Button,
  Nav,
  Navbar,
} from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faSlidersH } from "@fortawesome/free-solid-svg-icons";

import "./DashboardCarriers.css";
import Brand from "../../assets/img/brand/logo-white.svg";
import Profile3 from "../../assets/img/team/profile-picture-4.jpg";
import Table from "../../components/Tables/Table";
import { getLoads, selectLoad } from "../../services/api/loads";
import { Routes } from "../../routes";
import ModalForCarriersInfo from "./Modal/ModalForCarriersInfo";
import ModalForFilters from "./Modal/ModalForFilters";
import levelAccessConstants from "../../utils/constants/levelAccess.constants";
import LoadMobileTable from "./MobileTable/LoadMobileTable";

export default () => {
  const history = useHistory();

  const [levelId, setLevelId] = useState(null);
  const [nameAuth, setNameAuth] = useState("");
  const [modalData, setModalData] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filterValues, setFilterValues] = useState(null);
  const [timer, setTimer] = useState(null);
  const [lastFieldSearch, setLastFieldSearch] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(null);
  const [showModalForFilters, setShowModalForFilters] = useState(false);
  const navRef = useRef(null);

  const [isMovil, setIsMovil] = useState(false);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleClick = () => {
    if (navRef.current) {
      setIsCollapsed(false);
    }
  };

  const columns = [
    {
      header: "#",
      footer: (props) => props.column.id,
      accessorKey: "item.load_id",
      cell: (info) => info.getValue(),
      sort: true,
    },
    {
      header: "Origin",
      footer: (props) => props.column.id,
      accessorKey: "origin_city_and_state",
      cell: (info) => info.getValue(),
    },
    {
      header: "Destination",
      footer: (props) => props.column.id,
      accessorKey: "destination_city_and_state",
      cell: (info) => info.getValue(),
    },
    {
      header: "Loading condition",
      footer: (props) => props.column.id,
      accessorKey: "item.equipment_details",
      cell: (info) =>
        `${info.getValue()[0].toLocaleUpperCase()}${info.getValue().substr(1)}`,
      filter: "select",
      filterValues: [
        {
          id: "Full",
          text: "Full",
        },
        {
          id: "Partial",
          text: "Partial",
        },
      ],
    },
    {
      header: "Pickup",
      footer: (props) => props.column.id,
      accessorKey: "item.load_pickup",
      cell: (info) => info.getValue(),
    },
    {
      header: "Miles",
      footer: (props) => props.column.id,
      accessorKey: "item.miles",
      cell: (info) => info.getValue(),
    },
    {
      header: "Equipment",
      footer: (props) => props.column.id,
      accessorKey: "item.load_equipment_type.equipment_name",
      cell: (info) => info.getValue(),
    },
    {
      header: "Status",
      footer: (props) => props.column.id,
      accessorKey: "item.load_status",
      cell: (info) => {
        const statusVariant =
          info.row.original.item.load_status === "active"
            ? "success"
            : "danger";

        return (
          <span className={`text-${statusVariant}`}>{info.getValue()}</span>
        );
      },
    },
    {
      header: "Action",
      cell: (info) => {
        return levelId ? (
          <Button
            variant="primary"
            onClick={() => openModal(info.row.original)}
          >
            View more Details
          </Button>
        ) : (
          <Card.Link
            as={Link}
            to={Routes.Signin.path}
            className="fw-normal text-black"
          >
            {`Sign in  for Details`}
          </Card.Link>
        );
      },
    },
  ];
  const filterColumns = [
    "origin_city_and_state",
    "destination_city_and_state",
    "item_load_equipment_type.equipment_name",
    "item_equipment_details",
  ];
  const [initialSorting, setInitialSorting] = useState([
    {
      desc: true,
      id: "item_load_id",
    },
  ]);

  const openModal = (rowData) => {
    setModalData(rowData);
  };

  const setDataList = (response) => {
    setList(response.data);
    setPagination({
      pageCount: response.pagination.pageCount,
      pageSize: response.pagination.take,
      pageIndex: response.pagination.page - 1,
      itemCount: response.pagination.itemCount,
    });
  };

  /**
   * Asynchronous method to fetch a paginated list of loads with applied filters and sorting.
   * @param {number} numberPage - The page number to request.
   * @param {number} numberTake - The number of items to retrieve (page size).
   * @param {Object} filter - Object containing the filters to be applied to the load search. Filters can include:
   *  - `item_load_equipment_type.equipment_name`: Equipment name for the load.
   *  - `destination_city_and_state`: Destination city and state for the load.
   *  - `origin_city_and_state`: Origin city and state for the load.
   *  - `item_equipment_details`: Equipment loading condition details.
   *  - `equipment_name`: Filter for equipment name, possibly passed from a different component (e.g., a modal).
   * @param {string} sort - Optional sorting parameter to define the order of the results.
   *
   * In this case, the `filter` object may contain different filter properties depending on where it originates (e.g., from the modal
   * or other components). Using `...filter`, we ensure that all the provided filters are preserved while adding or transforming
   * specific ones based on the context.
   *
   *  1. `...filter`: Copies all the existing filter properties from the original `filter` object into the new object.
   *
   * 2. The code then adds or transforms specific properties:
   *    - `equipment_name`: It checks if `filter['item_load_equipment_type.equipment_name']` is present, and if not, it falls back
   *       to `filter.equipment_name`. If neither exists, it defaults to an empty string.
   *    - Other properties like `destination`, `origin`, and `equipment_loading_condition` are mapped directly from the existing `filter` object.
   *
   */
  const getPaginationList = useCallback(
    async (numberPage, numberTake, filter, sort) => {
      setLoading(true);
      // Transform structure
      if (filter) {
        filter = {
          ...filter,
          equipment_name:
            filter["item_load_equipment_type.equipment_name"] ??
            filter.equipment_name ??
            "",
          destination: filter["destination_city_and_state"] ?? "",
          origin: filter["origin_city_and_state"] ?? "",
          equipment_loading_condition: filter["item_equipment_details"] ?? "",
        };
      }

      const data = await getLoads(numberPage, numberTake, filter, sort);

      setDataList(data);

      setLoading(false);
    },
    []
  );

  const signOff = () => {
    window.localStorage.removeItem("authorization");
    window.localStorage.removeItem("user");
    history.push("sign-in");
  };

  const handleFilter = (filter) => {
    const isFilterText = columns.some(
      (column) =>
        column?.accessorKey?.replace(".", "_") === filter.id &&
        column?.filter !== "select"
    );

    if (timer && isFilterText) {
      clearTimeout(timer);
    }

    setLastFieldSearch(filter.id);

    const newFilter = {
      ...filterValues,
      [filter.id]: filter.value,
    };
    setFilterValues(newFilter);

    if (isFilterText) {
      const newTimer = setTimeout(async () => {
        getPaginationList(1, 10, newFilter);
      }, 700);
      setTimer(newTimer);

      return;
    }

    getPaginationList(1, 10, newFilter);
  };

  const handleSortingChange = (value) => {
    setInitialSorting(value);
    getPaginationList(1, 10, filterValues, value[0]);
  };

  const handleSelectLoad = async (currentId) => {
    const id = currentId ?? modalData.item.load_id;
    const response = await selectLoad(id);

    if (response === true) {
      const newList = list.map((record) => {
        if (record.item.load_id === id) {
          record.item.takenByCarrier = true;
        }

        return record;
      });

      setList(newList);
      setModalData(false);
    }
  };

  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem("user"));

    if (user) {
      setLevelId(user.level_access);
      setNameAuth(`${user.user_first_name} ${user.user_last_name}`);

      const levelAccessAdmin = levelAccessConstants.ADMIN;

      if (user.level_access === levelAccessAdmin) {
        history.push("/");
      }
    }
  }, [history]);

  useEffect(() => {
    getPaginationList(1, 10);
  }, [getPaginationList]);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setIsMovil(window.innerWidth >= 992);
  }, []);

  return (
    <>
      <Navbar
        variant="dark"
        expand="lg"
        expanded={isCollapsed}
        onToggle={handleToggle}
        className="navbar-transparent navbar-theme-primary mb-5 p-4"
        style={{ background: "#041423" }}
      >
        <Navbar.Brand href="/" className="me-lg-3">
          <Image src={Brand} style={{ height: "40px" }} />
        </Navbar.Brand>
        <Navbar.Collapse
          id="navbar-default-primary"
          ref={navRef}
          className="collapse-fullscreen w-100 justify-content-end"
        >
          <Nav className="navbar-nav-hover align-items-lg-center collapse-fullscreen-container">
            {!levelId && (
              <div className="d-flex justify-content-between navbar__header align-items-center">
                <Nav.Link href="/" className="nav__link">
                  Log in as carriers
                </Nav.Link>
                <button
                  type="button"
                  className="d-block d-sm-block d-md-block d-lg-none d-xl-none btn-close"
                  onClick={handleClick}
                ></button>
              </div>
            )}
            {levelId && (
              <Dropdown
                as={Nav.Item}
                className="d-flex justify-content-between dropdown__header"
              >
                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                  <div className="media d-flex align-items-center">
                    <Image
                      src={Profile3}
                      className="user-avatar md-avatar rounded-circle"
                    />
                    <button
                      type="button"
                      className="d-block d-sm-block d-md-block d-lg-none d-xl-none btn-close"
                      onClick={handleClick}
                    ></button>
                    <div className="media-body ms-2 text-white align-items-center d-none d-lg-block">
                      <span className="mb-0 font-small fw-bold">
                        {nameAuth}
                      </span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                {isMovil ? (
                  <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                    <Dropdown.Item
                      className="fw-bold"
                      onClick={() => signOff()}
                    >
                      <FontAwesomeIcon
                        icon={faSignOutAlt}
                        className="text-danger me-2"
                      />{" "}
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                ) : (
                  <button className="logout__button" onClick={() => signOff()}>
                    Logout
                  </button>
                )}
              </Dropdown>
            )}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Toggle aria-controls="navbar-default-primary" />
      </Navbar>
      <div className="filter-section d-block d-sm-block d-md-none d-lg-none d-xl-none">
        <button
          type="button"
          className="btn-filter"
          onClick={() => setShowModalForFilters((prev) => !prev)}
        >
          <div className="d-flex gap-2 align-items-center">
            Filters
            <FontAwesomeIcon
              icon={faSlidersH}
              className="me-2 ml-4 text-primary"
            />
          </div>
        </button>
      </div>
      {showModalForFilters && (
        <ModalForFilters
          closeModal={setShowModalForFilters}
          searchNewData={getPaginationList}
        />
      )}
      <Container fluid>
        <Row>
          <Col md={6} xs={12}>
            <h5>Truck loads found in real time</h5>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12} className="mb-4">
            <Row>
              <Col xs={12} xl={12} className="mb-4">
                <Row>
                  <Col xs={12} className="mb-4">
                    {loading && <h1>Loading...</h1>}
                    {!loading && isMobile && (
                      <LoadMobileTable
                        defaultKey="panel-1"
                        data={list}
                        levelId={levelId}
                        handleSelectLoad={handleSelectLoad}
                      />
                    )}
                    {!loading && !isMobile && (
                      <Table
                        columns={columns}
                        data={list}
                        pagination={pagination}
                        handlePagination={getPaginationList}
                        filterColumns={filterColumns}
                        handleFilter={handleFilter}
                        filterValues={filterValues}
                        lastFieldSearch={lastFieldSearch}
                        handleSortingChange={handleSortingChange}
                        initialSorting={initialSorting}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {modalData && (
        <ModalForCarriersInfo
          show={!!modalData}
          onHide={() => setModalData(false)}
          handleYes={handleSelectLoad}
          data={modalData}
        />
      )}
    </>
  );
};
