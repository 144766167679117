import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Image, Card, Button, Accordion, Row, Col } from "@themesberg/react-bootstrap";
import ModalForCarriersInfo from "../Modal/ModalForCarriersInfo";

import { Routes } from "../../../routes";
import moment from "moment-timezone";

export default (props) => {
  const { defaultKey, data = [], className = "show-test", handleSelectLoad } = props;
  console.log(data);
  const history = useHistory();

  const AccordionItem = (item) => {
    const { className, destination_city_and_state, origin_city_and_state } =
      item;

    const [modalData, setModalData] = useState(null);

    const openModal = () => {
      setModalData(item)
    };

    return (
      <div>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <Row className="d-flex">
              <Col sx={6} className="text-success">
                Dimensions & Weight
              </Col>
              <Col sx={6} className="d-flex align-items-end flex-column border-start">
                <p>{item.item.load_weight} fts</p>
                <p>{item.item.load_length} lbs</p>
              </Col>
            </Row>
            <Row className="d-flex mt-4  pt-3">
              <Col sx={4} className="text-success">
                Origin & Pick Up Times
              </Col>
              <Col sx={8} className="d-flex align-items-end flex-column border-start">
                <p>{origin_city_and_state}</p>
                <p>{moment(item.item.load_pickup).format("MM/DD/YYYY")}</p>
                <p>{item.item.load_pickup_hours}</p>
              </Col>
            </Row>
            <Row className="d-flex mt-4  pt-3">
              <Col sx={4} className="text-success">
                Destination & Drop off times
              </Col>
              <Col sx={8} className="d-flex align-items-end text-end flex-column">
                <p>{destination_city_and_state}</p>
                <p>{item.item.load_pickup_drop_off_hours}</p>
              </Col>
            </Row>
            <Row className="d-flex mt-4  pt-3">
              <Col sx={3} className="text-success">
                Equipment & Details
              </Col>
              <Col sx={9} className="d-flex align-items-end flex-column border-start text-end">
                <p>{item.item.load_equipment_type.equipment_name}</p>
                <p>{item.item.equipment_details}</p>
              </Col>
            </Row>
            <Row className="d-flex mt-4  pt-3">
              <Col sx={12}>
                {
                  !props.levelId ? (
                    <Button
                      as={Link}
                      to={Routes.Signin.path}
                    >
                      Sign in  for Details
                    </Button>
                  ) : (<Button
                    className="mt-4 btn-block btn btn-warning"
                    onClick={() => openModal()}
                  >
                    More Details
                  </Button>
                  )
                }
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {
          modalData && (
            <ModalForCarriersInfo
              show={!!modalData}
              onHide={() => setModalData(false)}
              handleYes={handleSelectLoad}
              data={modalData}
            />
          )
        }
      </div >
    );
  };

  return (
    <Accordion defaultActiveKey={defaultKey}>
      {!data.length && <p className="text-center">
        No results found
      </p>}
      {data.map((d) => (
        <AccordionItem
          key={`accordion-${d.item.load_id}`}
          {...d}
          className={className}
        />
      ))}

    </Accordion>
  );
};
